import { List } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BookableLuggagePolicy, PolicyUnits } from "../../../../shared/apiTypes/apiTypes";
import {
  translateLengthUnit,
  translateTimeUnit,
  translateWeightUnit,
} from "../../../../shared/helpers/translateUnits/translateUnits";
import BrandPolicyHeadline from "../brandPolicyHeadline/BrandPolicyHeadline";
import * as css from "./BookableLuggage.scss";
import getFeeTranslationKey from "./helper/getFeeTranslationKey";

export type BookableLuggageProps = {
  policyName:
    | "additionalLuggagePolicy"
    | "specialLuggagePolicy"
    | "pushchairPolicy"
    | "bikePolicy"
    | "surfboardPolicy"
    | "skiEquipmentPolicy"
    | "petPolicy";
  data: BookableLuggagePolicy;
  units: PolicyUnits;
};

const BookableLuggage: React.FC<BookableLuggageProps> = ({ policyName, data, units }) => {
  const intl = useIntl();

  const { luggageSpecification, bookable } = data;

  return (
    <>
      <BrandPolicyHeadline policyName={policyName} isAllowed={bookable} />
      {bookable && luggageSpecification !== null && (
        <List>
          {luggageSpecification.pointOfSale && (
            <li data-testid="point-of-sale">
              <FormattedMessage
                id="policies.pointOfSale.text"
                values={{
                  value: intl.formatMessage({
                    id: `pointOfSale.select.option.${luggageSpecification.pointOfSale}`,
                  }),
                }}
              />
            </li>
          )}

          {luggageSpecification.bookableUntil && (
            <li data-testid="bookable-until">
              <FormattedMessage
                id="policies.bookableUntil.text"
                values={{
                  value: luggageSpecification.bookableUntil.value,
                  timeUnit: intl.formatMessage({
                    id: translateTimeUnit(
                      luggageSpecification.bookableUntil.unit,
                      intl.formatPlural(luggageSpecification.bookableUntil.value)
                    ),
                  }),
                }}
              />
            </li>
          )}

          {luggageSpecification.maxWeight && (
            <li data-testid="max-weight">
              <FormattedMessage
                id="policies.maxWeight.text"
                values={{
                  value: luggageSpecification.maxWeight.value,
                  unit: translateWeightUnit(units.weightUnit),
                }}
              />
            </li>
          )}

          {luggageSpecification.maxDimension && (
            <li data-testid="dimensions">
              <FormattedMessage
                id="policies.dimensions.text"
                values={{
                  value: luggageSpecification.maxDimension.value,
                  unit: translateLengthUnit(units.lengthUnit),
                }}
              />
            </li>
          )}

          {luggageSpecification.feePrice && (
            <li data-testid="fee">
              <FormattedMessage
                id={getFeeTranslationKey(luggageSpecification.feeType)}
                values={{
                  value: <span className={css.redText}>{luggageSpecification.feePrice.value}</span>,
                  currency: <span className={css.redText}>{units.currency}</span>,
                  unit: translateLengthUnit(units.lengthUnit),
                }}
              />
            </li>
          )}
        </List>
      )}
    </>
  );
};

export default BookableLuggage;

import { ReactWrapper, ShallowWrapper } from "enzyme";
import Breakpoint from "../breakpoint/Breakpoint";

export const assertResponsiveRenderer = (
  wrapper: ShallowWrapper | ReactWrapper,
  expectedBreakpoint: Array<Breakpoint>,
  expectedChildren: Array<string>,
  position = 0
) => {
  expectedChildren.forEach((child) => {
    // @ts-ignore toContainMatchingElement is coming from jest-enzyme and all projects using
    // RTL complain about it. Thats why we need to ignore it.
    expect(wrapper.find("ResponsiveRenderer").at(position)).toContainMatchingElement(child);
  });

  expectedBreakpoint.forEach((breakpoint) => {
    expect(wrapper.find("ResponsiveRenderer").at(position).prop("hidden")).toContain(breakpoint);
  });
};

export const assertFormattedMessage = (
  wrapper: ShallowWrapper | ReactWrapper,
  expectedId: string,
  expectedValues?: object,
  position = 0
) => {
  // @ts-ignore toContainMatchingElement is coming from jest-enzyme and all projects using
  // RTL complain about it. Thats why we need to ignore it.
  expect(wrapper).toContainMatchingElement("FormattedMessage");

  expect(wrapper.find("FormattedMessage").at(position).prop("id")).toBe(expectedId);

  if (expectedValues) {
    expect(wrapper.find("FormattedMessage").at(position).prop("values")).toEqual(expectedValues);
  }
};

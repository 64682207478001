import { getCookie, setCookie } from "../handleCookies/handleCookies";
import { LocaleListItem } from "../locales/types";

export const COOKIE_LANGUAGE_NAME = "marketplace-language";

export const getUserPreferredLanguage = (): string => getCookie(COOKIE_LANGUAGE_NAME) || "en_US";

export const setUserPreferredLanguage = (locale: LocaleListItem): void => {
  setCookie(COOKIE_LANGUAGE_NAME, locale.locale, 10000);

  window.location.reload();
};

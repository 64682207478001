import { Heading } from "@flixbus/honeycomb-react";
import image from "@flixbus-phx/marketplace-common/assets/illustrations/magnifier.svg";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./BrandListNoResults.scss";

type Props = {
  filterValue?: string;
};

const BrandListNoResults: React.FC<Props> = ({ filterValue }) => {
  return (
    <div className={css.container} aria-busy="false">
      <img src={image} alt="" width={250} />
      <Heading size={2} extraClasses={css.text} flushSpace>
        <FormattedMessage id="carrierSearch.noResults.title" values={{ searchValue: <b>{filterValue?.trim()}</b> }} />
      </Heading>
      <Heading size={3} extraClasses={css.text}>
        <FormattedMessage id="carrierSearch.noResults.secondTitle" />
      </Heading>
    </div>
  );
};

export default BrandListNoResults;

import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import customerServiceContactAccordionId from "../../shared/helpers/customerServiceContactAccordionId/customerServiceContactAccordionId";
import * as css from "./PolicyNotSet.scss";

type Props = {
  hasContactInfo: boolean;
};

const PolicyNotSet: React.FC<Props> = ({ hasContactInfo }) => {
  const handleClickScroll = () => {
    const element = document.getElementById(customerServiceContactAccordionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.setAttribute("open", "true");
    }
  };

  if (!hasContactInfo) {
    return (
      <FormattedMessage data-testid="policies.notSet.noContactInfo.text" id="policies.notSet.noContactInfo.text" />
    );
  }

  return (
    <FormattedMessage
      data-testid="PolicyNotSet"
      id="policies.notSet.text"
      values={{
        link: (
          <Button appearance="link" extraClasses={css.link} onClick={handleClickScroll}>
            <FormattedMessage id="policies.notSet.link" />
          </Button>
        ),
      }}
    />
  );
};

export default PolicyNotSet;

import * as React from "react";
import Select from "react-select";
import ClearIndicator from "../../helper/reactSelect/clearIndicator/ClearIndicator";
import DropdownIndicator from "../../helper/reactSelect/dropdownIndicator/DropdownIndicator";
import Option from "../../helper/reactSelect/option/Option";
import SingleValue from "../../helper/reactSelect/singleValue/SingleValue";
import { SelectOption } from "../../helper/reactSelect/types";
import removeSpacesAndSpecialCharacters from "../../helper/removeSpacesAndSpecialCharacters/removeSpacesAndSpecialCharacters";
import autocompleteStyles from "../../styles/autocompleteStyles/autocompleteStyles";
import * as css from "./Autocomplete.scss";
import NoOptionsMessage from "./ui/noOptionsMessage/NoOptionsMessage";

export type AutocompleteProps = {
  label?: string;
  placeholder?: string;
  options: Array<SelectOption>;
  onValueSelected: (selectedValue: SelectOption) => void;
  onFocus?: () => void;
  startsOnInputValueLength?: number;
  noOptionsAction?: {
    action: () => void;
    message: string;
  };
  noOptionsInfo?: JSX.Element;
  optionsLoading?: boolean;
  error?: boolean;
  errorMessage?: string;
  isNarrow?: boolean;
  initialValue?: SelectOption;
  disabled?: boolean;
  onInputChange?: (value: string) => void;
  filterOption?: (option: SelectOption, inputValue: string) => boolean;
  onBlur?: () => void;
  blurInputOnSelect?: boolean;
  isClearable?: boolean;
  showValueOnly?: boolean;
  menuIsOpen?: boolean;
  clearsOnChange?: boolean;
  clearInputOnFocus?: boolean;
  clearsOnSelect?: boolean;
};

const Autocomplete: React.FC<AutocompleteProps> = ({
  label = undefined,
  placeholder = null,
  options,
  onValueSelected,
  onFocus = () => {},
  startsOnInputValueLength = 3,
  noOptionsAction,
  noOptionsInfo,
  optionsLoading,
  error = false,
  errorMessage,
  isNarrow = false,
  initialValue = null,
  disabled = false,
  onInputChange,
  filterOption,
  onBlur = undefined,
  blurInputOnSelect = false,
  isClearable = false,
  showValueOnly = false,
  menuIsOpen = false,
  clearsOnChange = false,
  clearInputOnFocus = false,
  clearsOnSelect = false,
}) => {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<SelectOption | null>(initialValue);

  React.useEffect(() => {
    if ((initialValue && !showValueOnly) || clearsOnChange) {
      setValue(initialValue);
    }

    if (initialValue && showValueOnly) {
      setValue({
        value: initialValue.value,
        label: initialValue.value,
      });
    }
  }, [initialValue]);

  React.useEffect(() => {
    setShowOptions(menuIsOpen);
  }, [menuIsOpen]);

  const handleOnInputChange = (inputValue: string): void => {
    setShowOptions(inputValue.length >= startsOnInputValueLength);

    if (onInputChange) {
      onInputChange(inputValue);
    }
  };

  const getNoOptionsElement: React.FC = () => {
    return (
      <NoOptionsMessage
        show={showOptions}
        loading={optionsLoading}
        noOptionsAction={
          noOptionsAction
            ? {
                ...noOptionsAction,
                action: () => {
                  noOptionsAction.action();
                  setShowOptions(false);
                },
              }
            : undefined
        }
        noOptionsInfo={noOptionsInfo}
      />
    );
  };

  return (
    <>
      {label && (
        <div className={css.labelWrapper}>
          <label className={css.label} htmlFor={`autocomplete-${label}`}>
            {label}
          </label>
        </div>
      )}
      <Select
        id={`autocomplete-${removeSpacesAndSpecialCharacters(label) || Math.random()}`}
        placeholder={placeholder}
        styles={autocompleteStyles(error, undefined, undefined, isClearable, !!label)}
        options={showOptions || startsOnInputValueLength === 0 ? options : undefined}
        onChange={(selectedValue) => {
          if (selectedValue && selectedValue instanceof Array === false) {
            onValueSelected(selectedValue as SelectOption);

            if (showValueOnly) {
              setValue({
                value: (selectedValue as SelectOption).value,
                label: (selectedValue as SelectOption).value,
              });
            } else {
              setValue(selectedValue as SelectOption);
            }

            if (clearsOnSelect) {
              setValue(null);
            }
          }

          if (isClearable && selectedValue === null) {
            onValueSelected(selectedValue as unknown as SelectOption);
            setValue(selectedValue);
          }
        }}
        onInputChange={handleOnInputChange}
        value={value}
        components={{
          ClearIndicator,
          DropdownIndicator,
          NoOptionsMessage: getNoOptionsElement,
          Option,
          SingleValue,
        }}
        inputId={`${removeSpacesAndSpecialCharacters(label) || Math.random()}-autocomplete`}
        data-id="autocomplete-select"
        onFocus={() => {
          if (clearInputOnFocus) {
            setValue(null);
          }

          onFocus();
        }}
        className={isNarrow ? css.narrow : ""}
        isDisabled={disabled}
        filterOption={filterOption}
        onBlur={onBlur}
        blurInputOnSelect={blurInputOnSelect}
        isClearable={isClearable}
        menuIsOpen={menuIsOpen ? true : undefined}
      />
      {error && (
        <span data-id="error-message" className={css.info}>
          {errorMessage}
        </span>
      )}
    </>
  );
};

export default Autocomplete;

import * as React from "react";
// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
const usePrevious = <T extends object>(value?: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;

import { Icon, IconComponent } from "@flixbus/honeycomb-icons-react";
import cx from "classnames";
import * as React from "react";
import * as css from "../styles.scss";
import { SelectOption } from "../types";

type Props = {
  label: SelectOption["label"];
  multiIconLabel: NonNullable<SelectOption["multiIconLabel"]>;
  extraClasses?: string;
};

const MultiIconLabel: React.FC<Props> = ({ label, multiIconLabel, extraClasses }) => {
  return (
    <div className={cx(css.multiWrapper, extraClasses)}>
      <div className={css.multiIconsWrapper}>
        {multiIconLabel.map((element: { icon: IconComponent; label: number }, i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <Icon InlineIcon={element.icon} extraClasses={css.multiIcon} />
            <span>{element.label}</span>
          </div>
        ))}
      </div>
      <div className={css.multiInfoWrapper}>
        <span>{label}</span>
      </div>
    </div>
  );
};

export default MultiIconLabel;

import { Tooltip, useSmartPosition } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import isTextOverflown from "../../helper/isTextOverflown/isTextOverflown";
import * as css from "./OverflownText.scss";

type Props = {
  tooltipContent: JSX.Element;
  isTooltipShownDefault?: boolean;
  extraClasses?: string;
};

const OverflownText: React.FC<Props> = ({ tooltipContent, isTooltipShownDefault, extraClasses, children }) => {
  const targetInnerRef = React.useRef(null);
  const balloonInnerRef = React.useRef(null);
  const [isActive, setIsActive] = React.useState(false);
  const [smartPosition, smartAlignment] = useSmartPosition({
    active: isActive,
    targetRef: targetInnerRef,
    elementRef: balloonInnerRef,
  });

  const [isTextOverFlown, setIsTextOverFlown] = React.useState(true);
  const elementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setIsTextOverFlown(isTextOverflown(elementRef));
  }, [elementRef]);

  const showTooltip = isTooltipShownDefault || isTextOverFlown;

  return (
    <div className={css.wrapper}>
      {showTooltip && (
        <Tooltip
          innerRef={targetInnerRef}
          balloonRef={balloonInnerRef}
          id="overflown-text"
          content={tooltipContent}
          openOnHover
          size="content-fit"
          position={smartPosition}
          alignment={smartAlignment}
          onToggle={setIsActive}
        >
          <div className={cx(css.textContainer, extraClasses)} ref={elementRef} data-id="overflown-text">
            {/* needs to be one or multiple html element(s) (e.g. <p>), because in isTextOverflown() 
            it is checked if child elements inside the ref container are overflowing. Css of this 
            component is only handling overflow of <p> elements */}
            {children}
          </div>
        </Tooltip>
      )}
      {!showTooltip && (
        <div className={cx(css.textContainer, extraClasses)} ref={elementRef} data-id="overflown-text">
          {children}
        </div>
      )}
    </div>
  );
};

export default OverflownText;

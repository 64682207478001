import { Calendar } from "@flixbus/honeycomb-react";
import { isBefore } from "date-fns";
import * as React from "react";
import HandleOutsideClick from "../handleOutsideClick/HandleOutsideClick";
import * as css from "./PeriodPicker.scss";
import {
  getDays,
  getEndDate,
  getMonths,
  getSelectionAsString,
  getStartDate,
} from "./helper/calendarHelper/calendarHelper";
import CalendarInput from "./ui/calendarInput/CalendarInput";
import CalendarTag from "./ui/calendarTag/CalendarTag";

export const getPeriodAsString = getSelectionAsString;

export type PeriodPickerProps = {
  inputElement: "input" | "tag";
  earliestStartDate: Date;
  latestEndDate: Date;
  periodStart?: Date;
  periodEnd?: Date;
  onChange: (start: Date, end: Date) => void;
  onDelete?: () => void;
  onFocus?: () => void;
  label: string;
  preventAutoFocus?: boolean;
  errorMessage?: string;
  placeholder?: string;
  required?: boolean;
  longestSelectablePeriodInDays?: number;
};

const PeriodPicker: React.FC<PeriodPickerProps> = ({
  inputElement,
  earliestStartDate,
  latestEndDate,
  periodStart,
  periodEnd,
  onChange,
  onDelete,
  onFocus,
  label,
  preventAutoFocus = false,
  errorMessage,
  placeholder,
  required = false,
  longestSelectablePeriodInDays,
}) => {
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const [showCalendar, setShowCalendar] = React.useState(false);

  const closeCalendarAndSubmitSelection = () => {
    setShowCalendar(false);
    if (onFocus) {
      onFocus();
    }
    if (startDate && endDate === undefined) {
      onChange(startDate, periodEnd && isBefore(startDate, periodEnd) ? periodEnd : startDate);
    } else if (startDate && endDate) {
      onChange(startDate, endDate);
    } else {
      return;
    }
    setStartDate(undefined);
    setEndDate(undefined);
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      closeCalendarAndSubmitSelection();
    }
  }, [startDate, endDate]);

  const handleDateSelect = (selected: Date) => {
    if (startDate === undefined) {
      setStartDate(selected);
      return;
    }

    if (endDate === undefined) {
      setEndDate(selected);
      return;
    }

    setStartDate(selected);
    setEndDate(undefined);
  };

  const selectedStartDate = startDate || periodStart;
  const selectedEndDate = endDate || periodEnd;

  return (
    <HandleOutsideClick
      onClickOutside={() => {
        if (showCalendar) {
          closeCalendarAndSubmitSelection();
        }
      }}
    >
      {inputElement === "input" && (
        <CalendarInput
          label={label}
          required={required}
          preventAutoFocus={preventAutoFocus}
          placeholder={placeholder}
          errorMessage={errorMessage}
          value={getSelectionAsString(selectedStartDate, selectedEndDate)}
          onFocus={() => setShowCalendar(true)}
        />
      )}
      {inputElement === "tag" && (
        <CalendarTag
          aria-label={label}
          onClick={() => setShowCalendar(true)}
          value={getSelectionAsString(selectedStartDate, selectedEndDate)}
          onDelete={() => {
            setStartDate(undefined);
            setEndDate(undefined);
            if (onDelete) {
              onDelete();
            }
          }}
        />
      )}
      <Calendar
        hidden={!showCalendar}
        id="period-calendar"
        appearance="compact"
        startDate={getStartDate(earliestStartDate, startDate, endDate)}
        endDate={getEndDate(latestEndDate, startDate, longestSelectablePeriodInDays)}
        startSelected={selectedStartDate}
        endSelected={selectedEndDate}
        defaultMonth={selectedStartDate}
        handleSelect={handleDateSelect}
        days={getDays()}
        months={getMonths()}
        extraClasses={css.calendar}
      />
    </HandleOutsideClick>
  );
};

export default PeriodPicker;

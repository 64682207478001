@use "variables" as *;

.wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  color: $content-secondary-color;
  padding: 0 0 2px;
  font-size: $font-size-primary;
  width: max-content;
}

.value {
  line-height: 28px;
}

import * as React from "react";
import normalizeString from "../normalizeString/normalizeString";

const highlightText = (text: string, filterValue?: string): JSX.Element | string => {
  if (filterValue) {
    const normalizedFilterValue = normalizeString(filterValue);
    const normalizedText = normalizeString(text);

    const parts = normalizedText.split(new RegExp(`(${normalizedFilterValue})`, "gi"));

    let indexStart = 0;

    if (parts.length > 1) {
      return (
        <span>
          {parts.map((part, i) => {
            const indexEnd = indexStart + part.length;
            const textPart = text.slice(indexStart, indexEnd);

            indexStart = indexEnd;

            if (part === normalizedFilterValue) {
              // eslint-disable-next-line react/no-array-index-key
              return <b key={i}>{textPart}</b>;
            } else {
              return textPart;
            }
          })}
        </span>
      );
    }
  }

  return text;
};

export default highlightText;

import * as React from "react";

/**
 * @param ref React Reference to the Element which position should be measured
 *
 * @return [topIsInViewport, rightIsInViewport, bottomIsInViewport, leftIsInViewport]
 
 *          topIsInViewport is the top edge of the element within the viewport
 *          rightIsInViewport is the right edge of the element within the viewport
 *          bottomIsInViewport is the bottom edge of the element within the viewport
 *          leftIsInViewport is the left edge of the element within the viewport
 */
const isElementInViewport = (ref: React.RefObject<any>): [boolean, boolean, boolean, boolean] => {
  const rect = ref.current.getBoundingClientRect();
  return [
    rect.top >= 0,
    rect.right <= (window.innerWidth || document.documentElement.clientWidth),
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight),
    rect.left >= 0,
  ];
};

export default isElementInViewport;

USER_HAS_MARKETPLACE_ACCESS: 
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
  - "ROLE_STATION_MANAGER"
USER_HAS_SERVICEDESK_ACCESS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
USER_HAS_VIEW_ACCESS_TO_ALL_PAGES: 
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
VIEW_PARTNER_LIST:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
VIEW_PARTNER:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
CREATE_PARTNER:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
DELETE_PARTNER_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
VIEW_DELETE_PARTNER:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_CURRENCY:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_NAME_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_NAME_PUBLISHED:
  - "ROLE_ADMIN"
EDIT_PARTNER_CONCESSION_OWNER_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
VIEW_PARTNER_CONCESSION_OWNER:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_BUSINESS_REGION_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
VIEW_PARTNER_BUSINESS_REGION:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_BUSINESS_DEV_MANAGER:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_DEFAULT_ACCESS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_DEFAULT_CAPACITY:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_MEANS_OF_TRANSPORT:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_CAPACITY_DECREASE_RULE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_DATA_PRIVACY_CONTRACT:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_BOOKING_REPORT_EMAIL:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_GENERAL_SETTINGS"
EDIT_PARTNER_PASSENGER_BOOKING_INFORMATION:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
VIEW_ALL_PARTNER_PASSENGER_BOOKING_INFORMATION_FIELDS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
EDIT_PARTNER_CHECK_IN_REQUIREMENTS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_PARTNER_CUSTOMER_SERVICE_INFORMATION:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_PARTNER_TERMS_AND_CONDITIONS:  
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_PARTNER_BRAND:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
VIEW_AVAILABLE_BRANDS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
VIEW_PARTNER_BRAND_POLICIES: 
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
EDIT_PARTNER_BRAND_POLICIES:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_PARTNER_SETTINGS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_IC_PRICING_RULES:
  - "ROLE_ADMIN"
  - "ROLE_OP"
VIEW_PARTNER_IC_PRICING_RULES:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PARTNER_TEAMS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
EDIT_PARTNER_PUBLISH_POLICIES:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
VIEW_PARTNER_TAGS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
EDIT_PARTNER_TAGS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
VIEW_PARTNER_USERS: 
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
VIEW_PARTNER_TEST_USERS:
  - "ROLE_ADMIN"
VIEW_LINE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
CREATE_LINE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_LINE_DESCRIPTION_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_LINE_CODE_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_LINE_CONTRACT_MODEL_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_LINE_BRAND:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
EDIT_LINE_CUT_OFF_SALES:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_LINE_AMENITIES:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
EDIT_LINE_MINIMUM_SEAT_CAPACITY:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_LINE_DEFAULT_CIRCULATION:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_LINE_MEANS_OF_TRANSPORT_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_LINE_FORBIDDEN_RELATIONS_ADD:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_LINE_FORBIDDEN_RELATIONS_DELETE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
DELETE_LINE_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
CREATE_SCHEDULE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
VIEW_SCHEDULE: 
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
VIEW_SCHEDULE_VALIDATION:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
CREATE_SCHEDULE_COPY:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
CREATE_SCHEDULE_CONCEPT_CHANGE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
DELETE_SCHEDULE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_SCHEDULE_NAME_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_SCHEDULE_PERIOD_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_SCHEDULE_INITIAL_CAPACITY_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_SCHEDULE_NON_OPERATIONAL_PERIOD_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_SCHEDULE_TIMETABLE_UNPUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_SCHEDULE_SUBMIT_TO_REVIEW:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
EDIT_SCHEDULE_APPROVE_REJECT:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_SCHEDULE_PUBLISH:
  - "ROLE_ADMIN"
  - "ROLE_OP"
VIEW_SCHEDULE_STATUS_HISTORY:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_SCHEDULE_STATUS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
VIEW_SCHEDULE_DETAILED_STATUS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
EDIT_PRICING:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_PRICING"
VIEW_ALL_OPS_PARTNER:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
VIEW_RIDES: 
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
EDIT_CAPACITY_MANAGER_CAPACITY: 
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_CAPACITY_MANAGER"
EDIT_CAPACITY_MANAGER_STOP_OR_RESTART_SALES: 
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_CAPACITY_MANAGER"
EDIT_CAPACITY_MANAGER_CANCEL_RIDE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_CAPACITY_MANAGER"
VIEW_CAPACITY_MANAGER_RIDES_IN_PREVIEW:
  - "ROLE_ADMIN"
  - "ROLE_OP"
VIEW_BOOKINGS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
VIEW_BOOKING_REPORTS_DOWNLOAD:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_REPORTING"
CREATE_STATION:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_STATION_MANAGER"
VIEW_STATIONS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
  - "ROLE_STATION_MANAGER"
CREATE_CITY:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_STATION_MANAGER"
VIEW_CITIES:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_PARTNER"
  - "ROLE_STATION_MANAGER"
CREATE_STATION_IMPORT:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_STATION_MANAGER"
CREATE_CITY_IMPORT:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_STATION_MANAGER"
EDIT_STATION_INREVIEW:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_STATION_MANAGER"
EDIT_STATION_APPROVED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_STATION_MANAGER"
EDIT_STATION_PUBLISHED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_STATION_MANAGER"
EDIT_STATION_REJECTED:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_STATION_MANAGER"
EDIT_STATION_APPROVE_REJECT:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_STATION_MANAGER"
EDIT_CITY_STATION_APPROVER:
  - "ROLE_ADMIN"
  - "ROLE_OP"
EDIT_SCHEDULE_ACTIVATE_ON_SALE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
EDIT_SCHEDULE_ARCHIVE:
  - "ROLE_ADMIN"
  - "ROLE_OP"
USER_HAS_MUS_ACCESS: 
  - "ROLE_USER_MANAGER"
  - "ROLE_CAPACITY_MANAGER"
  - "ROLE_GENERAL_SETTINGS"
  - "ROLE_PRICING"
  - "ROLE_SCHEDULE_MANAGER"
  - "ROLE_REPORTING"
  - "ROLE_DISPATCHER"
  - "ROLE_DRIVER"
  - "ROLE_POWER_BI"
  - "ROLE_PARTNER"
VIEW_POWER_BI_REPORT:
  - "ROLE_ADMIN"
  - "ROLE_POWER_BI"
VIEW_PAX_LIST:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_DISPATCHER"
  - "ROLE_DRIVER"
CHECK_IN_MANUAL:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_DISPATCHER"
CHECK_IN_QR:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
  - "ROLE_DRIVER"
CHANGE_PARTNER:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
VIEW_HIDDEN_STATUS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"
UPLOAD_FORBIDDEN_RELATIONS:
  - "ROLE_ADMIN"
  - "ROLE_OP"
  - "ROLE_BD"

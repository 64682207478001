import { Popup as HCPopup } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./Popup.scss";

export type PopupProps = {
  active: boolean;
  onClose: () => void;
  isLeft?: boolean;
  allowOverflow?: boolean;
  isWide?: boolean;
};

const Popup: React.FC<PopupProps> = ({ active, onClose, isLeft, allowOverflow, isWide, children }) => {
  // TODO: Remove when fixed in Honeycomb, https://jira.flix.tech/servicedesk/customer/portal/117/HNY-174
  // React.useEffect(() => {
  //   return () => {
  //     document.body.style.overflow = "";
  //   };
  // }, []);

  return (
    <>
      <HCPopup
        data-id={active ? "active-popup" : "inactive-popup"}
        active={active}
        extraClasses={cx(
          css.popup,
          isLeft && css.contentLeft,
          allowOverflow && css.allowOverflow,
          isWide && css.isWide
        )}
        onOverlayClick={onClose}
      >
        {children}
      </HCPopup>
    </>
  );
};

export default Popup;

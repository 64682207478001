const serviceDesk = "https://flixtech.atlassian.net/servicedesk/customer/portal/38";

const musLocation = `${process.env.MUS_LOCATION || "/myaccount"}`;

export const commonPathnames = {
  userHome: "/",

  // Links to MPP application
  mpp: "https://mpp.flixbus.com",

  // Internal links to service desk
  serviceDesk,
  serviceDeskRequestLogin: `${serviceDesk}/group/77/create/363`,
  serviceDeskReportProblem: `${serviceDesk}/group/77/create/349`,

  // Links to MUS application
  mus: musLocation,
};

const mpcLocation = `${process.env.MPC_LOCATION || "/connect"}`;
const opsLocation = `${process.env.OPS_LOCATION || "/ops"}`;

export const mpoPathnames = {
  userHome: mpcLocation,
  capacityManager: "/",
  bookingInfo: "/reports",
  dataReports: "/data-reports",
  faq: "/faq",
  bookingReportsDownload: "/reports/download",

  partnerOverview: "/partners",
  partnerOverviewWithReturnLocation: (pathname: string) => `/partners?returnTo=${pathname}`,

  // Links to MPC application
  mpc: mpcLocation,
  showStations: `${mpcLocation}/stations`,
  paxList: "/checkin/:rideId",
};

export const mpcPathnames = {
  userHome: commonPathnames.userHome, // If you are interested in what's shown on "/" see Router.tsx

  createPartner: "/partner/new",
  showPartnerDetail: "/partner/:partnerId",

  createLine: "/line/new",
  showLineDetail: "/line/:lineId",

  createSchedule: "/schedule/new",
  showScheduleDetail: "/schedule/:scheduleId",

  showStations: "/stations",

  showCountries: "/countries",

  // Links to MPO application
  capacityManager: opsLocation,
  bookingInfo: `${opsLocation}/reports`,
  faq: `${opsLocation}/faq`,
  dataReports: `${opsLocation}/data-reports`,

  // Links to MPP application
  mpp: commonPathnames.mpp,

  // Internal links to service desk
  serviceDesk: commonPathnames.serviceDesk,
};

export const musPathnames = {
  userHome: mpcLocation,

  // Links to MPO application
  capacityManager: opsLocation,
  bookingInfo: `${opsLocation}/reports`,
  faq: `${opsLocation}/faq`,
  dataReports: `${opsLocation}/data-reports`,

  // Links to MPC application
  mpc: mpcLocation,
  showStations: `${mpcLocation}/stations`,

  // Links to MPP application
  mpp: commonPathnames.mpp,
};

import { LocaleListExternal, LocaleListInternal, LocaleListItem, LocaleRegion } from "./types";

const sortLocales = (a: LocaleListItem, b: LocaleListItem): -1 | 0 | 1 =>
  a.name > b.name ? 1 : b.name > a.name ? -1 : 0;

export const internalLocales: LocaleListInternal = [
  {
    locale: "en_US",
    name: "English",
    flagName: "us",
    bcp47Locale: "en-US",
    iso6391: "en",
    drupal: "en-us",
  },
  {
    locale: "de",
    name: "Deutsch",
    flagName: "de",
    bcp47Locale: "de",
    iso6391: "de",
    drupal: "de",
  },
  {
    locale: "hr",
    name: "Hrvatski",
    flagName: "hr",
    bcp47Locale: "hr",
    iso6391: "hr",
    drupal: "hr",
  },
  {
    locale: "fr",
    name: "Français",
    flagName: "fr",
    bcp47Locale: "fr",
    iso6391: "fr",
    drupal: "fr",
  },
  {
    locale: "it",
    name: "Italiano",
    flagName: "it",
    bcp47Locale: "it",
    iso6391: "it",
    drupal: "it",
  },
  {
    locale: "pl",
    name: "Polski",
    flagName: "pl",
    bcp47Locale: "pl",
    iso6391: "pl",
    drupal: "pl",
  },
  {
    locale: "pt_PT",
    name: "Português",
    flagName: "pt",
    bcp47Locale: "pt",
    iso6391: "pt",
    drupal: "pt-pt",
  },
  {
    locale: "pt_BR",
    name: "Português (Brasil)",
    flagName: "br",
    bcp47Locale: "pt-BR",
    iso6391: "pt",
    drupal: "pt-br",
  },
  {
    locale: "es",
    name: "Español",
    flagName: "es",
    bcp47Locale: "es",
    iso6391: "es",
    drupal: "es",
  },
  {
    locale: "uk",
    name: "Українська",
    flagName: "ua",
    bcp47Locale: "uk",
    iso6391: "uk",
    drupal: "uk",
  },
  {
    locale: "tr",
    name: "Türk",
    flagName: "tr",
    bcp47Locale: "tr",
    iso6391: "tr",
    drupal: "tr",
  },
];

export const defaultExternalLocale: Required<LocaleListItem> = {
  locale: "en",
  name: "English",
  flagName: "eu",
  bcp47Locale: "en",
  region: LocaleRegion.EUROPE,
  iso6391: "en",
  drupal: "en-gl",
};

export const externalLocales: LocaleListExternal = [
  defaultExternalLocale,
  {
    locale: "at",
    name: "Deutsch",
    flagName: "at",
    bcp47Locale: "de-AT",
    region: LocaleRegion.EUROPE,
    iso6391: "de",
    drupal: "de-at",
  },
  {
    locale: "bg",
    name: "Български",
    flagName: "bg",
    bcp47Locale: "bg",
    region: LocaleRegion.EUROPE,
    iso6391: "bg",
    drupal: "bg",
  },
  {
    locale: "bs",
    name: "Bosanski",
    flagName: "ba",
    bcp47Locale: "bs",
    region: LocaleRegion.EUROPE,
    iso6391: "bs",
    drupal: "bs",
  },
  {
    locale: "ca",
    name: "Català",
    flagName: "ct",
    bcp47Locale: "ca",
    region: LocaleRegion.EUROPE,
    iso6391: "ca",
    drupal: "ca",
  },
  {
    locale: "cs",
    name: "Čeština",
    flagName: "cz",
    bcp47Locale: "cs",
    region: LocaleRegion.EUROPE,
    iso6391: "cs",
    drupal: "cs",
  },
  {
    locale: "da",
    name: "Dansk",
    flagName: "dk",
    bcp47Locale: "da",
    region: LocaleRegion.EUROPE,
    iso6391: "da",
    drupal: "da",
  },
  {
    locale: "de",
    name: "Deutsch",
    flagName: "de",
    bcp47Locale: "de",
    region: LocaleRegion.EUROPE,
    iso6391: "de",
    drupal: "de",
  },
  {
    locale: "de_CH",
    name: "Deutsch",
    flagName: "ch",
    bcp47Locale: "de-CH",
    region: LocaleRegion.EUROPE,
    iso6391: "de",
    drupal: "de-ch",
  },
  {
    locale: "en_CA",
    name: "English",
    flagName: "ca",
    bcp47Locale: "en-CA",
    region: LocaleRegion.AMERICAS,
    iso6391: "en",
    drupal: "en-ca",
  },
  {
    locale: "en_GB",
    name: "English",
    flagName: "gb",
    bcp47Locale: "en-GB",
    region: LocaleRegion.EUROPE,
    iso6391: "en",
    drupal: "en-gb",
  },
  {
    locale: "en_US",
    name: "English",
    flagName: "us",
    bcp47Locale: "en-US",
    region: LocaleRegion.AMERICAS,
    iso6391: "en",
    drupal: "en-us",
  },
  {
    locale: "es",
    name: "Español",
    flagName: "es",
    bcp47Locale: "es",
    region: LocaleRegion.EUROPE,
    iso6391: "es",
    drupal: "es",
  },
  {
    locale: "es_MX",
    name: "Español",
    flagName: "mx",
    bcp47Locale: "es-MX",
    region: LocaleRegion.AMERICAS,
    iso6391: "es",
    drupal: "es-mx",
  },
  {
    locale: "es_US",
    name: "Español",
    flagName: "us",
    bcp47Locale: "es-US",
    region: LocaleRegion.AMERICAS,
    iso6391: "es",
    drupal: "es-us",
  },
  {
    locale: "et_EE",
    name: "Estonian",
    flagName: "ee",
    bcp47Locale: "et",
    region: LocaleRegion.EUROPE,
    iso6391: "et",
    drupal: "et",
  },
  {
    locale: "fr",
    name: "Français",
    flagName: "fr",
    bcp47Locale: "fr",
    region: LocaleRegion.EUROPE,
    iso6391: "fr",
    drupal: "fr",
  },
  {
    locale: "fr_BE",
    name: "Français",
    flagName: "be",
    bcp47Locale: "fr-BE",
    region: LocaleRegion.EUROPE,
    iso6391: "fr",
    drupal: "fr-be",
  },
  {
    locale: "fr_CA",
    name: "Français",
    flagName: "ca",
    bcp47Locale: "fr-CA",
    region: LocaleRegion.AMERICAS,
    iso6391: "fr",
    drupal: "fr-ca",
  },
  {
    locale: "fr_CH",
    name: "Français",
    flagName: "ch",
    bcp47Locale: "fr-CH",
    region: LocaleRegion.EUROPE,
    iso6391: "fr",
    drupal: "fr-ch",
  },
  {
    locale: "hr",
    name: "Hrvatski",
    flagName: "hr",
    bcp47Locale: "hr",
    region: LocaleRegion.EUROPE,
    iso6391: "hr",
    drupal: "hr",
  },
  {
    locale: "hu",
    name: "Magyar",
    flagName: "hu",
    bcp47Locale: "hu",
    region: LocaleRegion.EUROPE,
    iso6391: "hu",
    drupal: "hu",
  },
  {
    locale: "it",
    name: "Italiano",
    flagName: "it",
    bcp47Locale: "it",
    region: LocaleRegion.EUROPE,
    iso6391: "it",
    drupal: "it",
  },
  {
    locale: "it_CH",
    name: "Italiano",
    flagName: "ch",
    bcp47Locale: "it-CH",
    region: LocaleRegion.EUROPE,
    iso6391: "it",
    drupal: "it-ch",
  },
  {
    locale: "lt_LT",
    name: "Lithuanian",
    flagName: "lt",
    bcp47Locale: "lt",
    region: LocaleRegion.EUROPE,
    iso6391: "lt",
    drupal: "lt",
  },
  {
    locale: "lv_LV",
    name: "Latvian",
    flagName: "lv",
    bcp47Locale: "lv",
    region: LocaleRegion.EUROPE,
    iso6391: "lv",
    drupal: "lv",
  },
  {
    locale: "mk",
    name: "Македонски",
    flagName: "mk",
    bcp47Locale: "mk",
    region: LocaleRegion.EUROPE,
    iso6391: "mk",
    drupal: "mk",
  },
  {
    locale: "nl",
    name: "Nederlands",
    flagName: "nl",
    bcp47Locale: "nl",
    region: LocaleRegion.EUROPE,
    iso6391: "nl",
    drupal: "nl",
  },
  {
    locale: "nl_BE",
    name: "Nederlands",
    flagName: "be",
    bcp47Locale: "nl-BE",
    region: LocaleRegion.EUROPE,
    iso6391: "nl",
    drupal: "nl-be",
  },
  {
    locale: "no",
    name: "Norsk (bokmål)",
    flagName: "no",
    bcp47Locale: "nb",
    region: LocaleRegion.EUROPE,
    iso6391: "nb",
    drupal: "nb",
  },
  {
    locale: "pl",
    name: "Polski",
    flagName: "pl",
    bcp47Locale: "pl",
    region: LocaleRegion.EUROPE,
    iso6391: "pl",
    drupal: "pl",
  },
  {
    locale: "pt",
    name: "Português",
    flagName: "pt",
    bcp47Locale: "pt",
    region: LocaleRegion.EUROPE,
    iso6391: "pt",
    drupal: "pt-pt",
  },
  {
    locale: "pt_BR",
    name: "Português",
    flagName: "br",
    bcp47Locale: "pt-BR",
    region: LocaleRegion.AMERICAS,
    iso6391: "pt",
    drupal: "pt-br",
  },
  {
    locale: "ro",
    name: "Română",
    flagName: "ro",
    bcp47Locale: "ro",
    region: LocaleRegion.EUROPE,
    iso6391: "ro",
    drupal: "ro",
  },
  {
    locale: "sk",
    name: "Slovenčina",
    flagName: "sk",
    bcp47Locale: "sk",
    region: LocaleRegion.EUROPE,
    iso6391: "sk",
    drupal: "sk",
  },
  {
    locale: "sl",
    name: "Slovenščina",
    flagName: "si",
    bcp47Locale: "sl",
    region: LocaleRegion.EUROPE,
    iso6391: "sl",
    drupal: "sl",
  },
  {
    locale: "sq",
    name: "Shqip",
    flagName: "al",
    bcp47Locale: "sq",
    region: LocaleRegion.EUROPE,
    iso6391: "sq",
    drupal: "sq",
  },
  {
    locale: "sr_Latn_RS",
    name: "Srpski",
    flagName: "rs",
    bcp47Locale: "sr",
    region: LocaleRegion.EUROPE,
    iso6391: "sr",
    drupal: "sr",
  },
  {
    locale: "sv",
    name: "Svenska",
    flagName: "se",
    bcp47Locale: "sv",
    region: LocaleRegion.EUROPE,
    iso6391: "sv",
    drupal: "sv",
  },
  {
    locale: "tr",
    name: "Türkçe",
    flagName: "tr",
    bcp47Locale: "tr",
    region: LocaleRegion.EUROPE,
    iso6391: "tr",
    drupal: "tr",
  },
  {
    locale: "uk",
    name: "Українська",
    flagName: "ua",
    bcp47Locale: "uk",
    region: LocaleRegion.EUROPE,
    iso6391: "uk",
    drupal: "uk",
  },
  {
    locale: "zh_US",
    name: "中文",
    flagName: "us",
    bcp47Locale: "zh",
    region: LocaleRegion.AMERICAS,
    iso6391: "zh",
    drupal: "zh-us",
  },
];

export const externalLocalesEurope: LocaleListExternal = externalLocales
  .filter((locale) => locale.region === LocaleRegion.EUROPE)
  .sort(sortLocales);

export const externalLocalesAmericas: LocaleListExternal = externalLocales
  .filter((locale) => locale.region === LocaleRegion.AMERICAS)
  .sort(sortLocales);

export const getLanguageObjectBasedOnLocale = (locale: string, isInternal: boolean): LocaleListItem => {
  let currentLanguage;

  if (isInternal) {
    currentLanguage = internalLocales.find((l) => l.locale === locale);
  } else {
    let localeMappedToPhrase = locale;
    if (locale === "pt_PT") {
      localeMappedToPhrase = "pt";
    }

    currentLanguage = externalLocales.find((l) => l.locale === localeMappedToPhrase);
  }

  return currentLanguage || defaultExternalLocale;
};

import { LocalDate, LocalDateTime } from "@js-joda/core";

export const parseDate = (date: string): Date => {
  const localDate = LocalDate.parse(date);

  return new Date(localDate.year(), localDate.monthValue() - 1, localDate.dayOfMonth(), 12, 0, 0);
};

export const parseLocalDate = (date: LocalDate): Date => {
  return new Date(date.year(), date.monthValue() - 1, date.dayOfMonth(), 12, 0, 0);
};

export const parseDateTime = (dateTime: string): Date => {
  const localDate = LocalDateTime.parse(dateTime);
  return new Date(localDate.year(), localDate.monthValue() - 1, localDate.dayOfMonth(), 12, 0, 0);
};

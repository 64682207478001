import * as React from "react";
import { components, OptionProps as ReactSelectOptionProps } from "react-select";
import MultiIconLabel from "../multiIconLabel/MultiIconLabel";
import * as css from "../styles.scss";
import { SelectOption } from "../types";

export type OptionProps = ReactSelectOptionProps<SelectOption, false>;

const Option: React.FC<OptionProps> = (props) => {
  if (props.data.description) {
    return (
      <components.Option {...props}>
        <div className={css.optionWithDescriptionWrapper}>
          <span>{props.data.label}</span>
          <span>{props.data.description}</span>
        </div>
      </components.Option>
    );
  }

  if (props.data.multiIconLabel) {
    return (
      <components.Option {...props}>
        <MultiIconLabel label={props.data.label} multiIconLabel={props.data.multiIconLabel} />
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      <div className={css.optionWrapper}>
        {props.data.icon && <span className={css.icon}>{props.data.icon}</span>}
        <span className={css.optionLabel}>{props.data.label}</span>
      </div>
    </components.Option>
  );
};

export default Option;

import { StylesConfig } from "react-select";

const dangerColor = "var(--hcr-danger-color)";
const primaryBgColor = "var(--hcr-bg-primary-color)";
const primaryContentColor = "var(--hcr-content-primary-color)";
const highlightColor = "var(--hcr-highlight-color)";
const secondaryContentColor = "var(--hcr-content-secondary-color)";
const borderRadius = "var(--hcr-primary-border-radius)";
const inputHeightDesktop = "var(--hcr-input-height-desktop)";
const inputHeightMobile = "var(--hcr-input-height-mobile)";
const hoverLayerColor = "var(--hcr-hover-layer-color)";
const inputBorderColor = "var(--hcr-input-border-color)";
const inputHeightWithoutBorderDesktop = "34px";
const spacingHalf = "var(--hcr-spacing-half)";
const uiPrimaryColor = "var(--hcr-ui-primary-color)";
const boxShadow = "var(--hcr-primary-box-shadow)";
const iconPrimaryColor = "var(--hcr-icon-primary-color)";

const getControlBorderColor = (hasError: boolean): string => {
  if (hasError) {
    return dangerColor;
  }
  return inputBorderColor;
};

const autocompleteStyles = (
  withError: boolean,
  isSelect = false,
  isDropUp = false,
  isClearable = false,
  hasLabel = false
): StylesConfig<any, any> => ({
  container: (provided: any, state: { isDisabled: boolean }) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    top: hasLabel && "3px",
    "@media only screen and (max-width: 1199px)": {
      ...provided["@media only screen and (max-width: 1199px)"],
      top: hasLabel && "-23px",
    },
    cursor: isSelect ? "pointer" : "text",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: secondaryContentColor,
    fontStyle: "italic",
  }),
  control: (provided: any, state: { menuIsOpen: boolean }) => ({
    ...provided,
    backgroundColor: primaryBgColor,
    borderColor: getControlBorderColor(withError),
    boxShadow: "none",
    outline: "none",
    overflow: "hidden",
    borderRadius,
    borderBottomRightRadius: state.menuIsOpen && !isDropUp ? "0px" : borderRadius,
    borderBottomLeftRadius: state.menuIsOpen && !isDropUp ? "0px" : borderRadius,
    borderTopRightRadius: state.menuIsOpen && isDropUp ? "0px" : borderRadius,
    borderTopLeftRadius: state.menuIsOpen && isDropUp ? "0px" : borderRadius,
    height: inputHeightDesktop,
    minHeight: inputHeightDesktop,
    "@media only screen and (max-width: 1199px)": {
      ...provided["@media only screen and (max-width: 1199px)"],
      height: inputHeightMobile,
      minHeight: inputHeightMobile,
    },
  }),
  input: (provided) => ({
    ...provided,
    color: primaryContentColor,
  }),
  option: (provided, state: { isSelected: boolean; isFocused: boolean; isDisabled: boolean }) => ({
    ...provided,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    backgroundColor: state.isSelected ? highlightColor : state.isFocused ? hoverLayerColor : "none",
    "&:hover": {
      backgroundImage: hoverLayerColor,
    },

    color: "inherit",

    cursor: "pointer",
    display: state.isDisabled ? "none" : undefined,
    padding: state.isSelected ? "4px 12px 4px 9px" : "4px 12px",
    margin: "4px 0px",

    borderLeft: state.isSelected ? `${spacingHalf} solid ${uiPrimaryColor}` : "unset",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
    zIndex: 100,
    backgroundColor: "unset",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: primaryBgColor,
    maxHeight: isSelect ? "200px" : provided.maxHeight,
    overflow: isSelect ? "scroll" : provided.overflow,
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    boxShadow,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    backgroundColor: primaryBgColor,
    position: "unset",
    height: inputHeightWithoutBorderDesktop,
    "@media only screen and (max-width: 1199px)": {
      ...provided["@media only screen and (max-width: 1199px)"],
      height: inputHeightMobile,
    },
    cursor: isSelect ? "pointer" : "text",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: primaryContentColor,
    width: "100%",
    maxWidth: isClearable ? "calc(100% - 85px)" : "calc(100% - 55px)",
    position: "absolute",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    backgroundColor: primaryBgColor,
    height: inputHeightWithoutBorderDesktop,
    "@media only screen and (max-width: 1199px)": {
      ...provided["@media only screen and (max-width: 1199px)"],
      height: inputHeightMobile,
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: "12px",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
    color: iconPrimaryColor,
  }),
});

export default autocompleteStyles;

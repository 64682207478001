import { List } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PassengerWithDisabilityPolicy } from "../../../../shared/apiTypes/apiTypes";
import { translateTimeUnit } from "../../../../shared/helpers/translateUnits/translateUnits";
import BrandPolicyHeadline from "../brandPolicyHeadline/BrandPolicyHeadline";

type BrandPoliciesProps = {
  data: PassengerWithDisabilityPolicy;
};

const PassengerWithDisability: React.FC<BrandPoliciesProps> = ({ data }) => {
  const intl = useIntl();

  const { passengerWithDisabilitySpecification, supported } = data;

  return (
    <>
      <BrandPolicyHeadline policyName="passengerWithDisabilityPolicy" isAllowed={supported} />
      {supported && passengerWithDisabilitySpecification !== null && (
        <List data-testid="list">
          {passengerWithDisabilitySpecification.supportingServices.length > 0 && (
            <li data-testid="supporting-services">
              <FormattedMessage id="policies.passengerWithDisabilityPolicy.supportingServices.label" />
              <List>
                {passengerWithDisabilitySpecification.supportingServices.map((item) => (
                  <li data-testid="supporting-services-item" key={item}>
                    <FormattedMessage id={`policies.passengerWithDisabilityPolicy.supportingServices.option.${item}`} />
                  </li>
                ))}
              </List>
            </li>
          )}
          {passengerWithDisabilitySpecification.registrationUntil && (
            <li data-testid="point-of-sale">
              <FormattedMessage
                id="policies.pointOfSale.text"
                values={{
                  value: intl.formatMessage({
                    id: `pointOfSale.select.option.CUSTOMER_SERVICE`,
                  }),
                }}
              />
            </li>
          )}
          {passengerWithDisabilitySpecification.registrationUntil && (
            <li data-testid="bookable-until">
              <FormattedMessage
                id="policies.bookableUntil.text"
                values={{
                  value: passengerWithDisabilitySpecification.registrationUntil.value,
                  timeUnit: intl.formatMessage({
                    id: translateTimeUnit(
                      passengerWithDisabilitySpecification.registrationUntil.unit,
                      intl.formatPlural(passengerWithDisabilitySpecification.registrationUntil.value)
                    ),
                  }),
                }}
              />
            </li>
          )}
          {passengerWithDisabilitySpecification.proofOfDisabilityRequired && (
            <li
              data-testid="proof-of-disability-required"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: `policies.passengerWithDisabilityPolicy.proofOfDisability.${
                    passengerWithDisabilitySpecification.proofOfDisabilityRequired ? "required" : "notRequired"
                  }`,
                }),
              }}
            />
          )}
        </List>
      )}
    </>
  );
};

export default PassengerWithDisability;

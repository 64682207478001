import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  primary: boolean;
};

const RetryButton: React.FC<Props> = ({ primary }) => (
  <Button
    data-id="retry-button"
    appearance={primary ? "primary" : undefined}
    onClick={() => {
      window.localStorage.clear();
      window.location.reload();
    }}
  >
    <FormattedMessage id="retry.button.text" />
  </Button>
);

export default RetryButton;

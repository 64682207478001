import { Icon, IconArrowDown } from "@flixbus/honeycomb-icons-react";
import {
  Flag,
  LanguageSwitcherPopup,
  LanguageSwitcher as HCLanguageSwitcher,
  LanguageSwitcherPopupItem,
  LanguageSwitcherPopupRegion,
  LanguageSwitcherToggle,
} from "@flixbus/honeycomb-react";
import * as React from "react";
import { LocaleListInternal, LocaleListItem } from "../../helper";
import * as css from "./LanguageSwitcher.scss";
import LANGUAGE_SWITCHER_PORTAL_ID from "./LanguageSwitcherPortalId";

export type LanguageSwitcherProps = {
  onLanguageSelected: (locale: LocaleListItem) => void;
  languages: Array<{ region: string; locales: LocaleListInternal }>;
  currentLanguage: LocaleListItem;
  toggleIcon?: boolean;
};

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  onLanguageSelected,
  languages,
  currentLanguage,
  toggleIcon,
}) => {
  return (
    <HCLanguageSwitcher portalId={LANGUAGE_SWITCHER_PORTAL_ID}>
      <LanguageSwitcherToggle
        lang={currentLanguage.bcp47Locale}
        extraClasses={css.toggle}
        flag={<Flag countryCode={currentLanguage.flagName} countryName={currentLanguage.name} />}
      >
        {currentLanguage.name}
        {toggleIcon && <Icon InlineIcon={IconArrowDown} />}
      </LanguageSwitcherToggle>

      <LanguageSwitcherPopup aria-label="Language selector." closeLabel="Close language selector.">
        {languages.map((region) => (
          <LanguageSwitcherPopupRegion key={region.region} title={region.region}>
            {region.locales.map((lang) => (
              <LanguageSwitcherPopupItem
                active={lang.locale === currentLanguage.locale}
                key={lang.locale}
                lang={lang.bcp47Locale}
                flag={<Flag countryCode={lang.flagName} countryName={lang.name} />}
                onClick={() => {
                  onLanguageSelected(lang);
                }}
              >
                {lang.name}
              </LanguageSwitcherPopupItem>
            ))}
          </LanguageSwitcherPopupRegion>
        ))}
      </LanguageSwitcherPopup>
    </HCLanguageSwitcher>
  );
};

export default LanguageSwitcher;

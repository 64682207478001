import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { logout } from "../../../../../auth/utils/utils";

type Props = {
  primary: boolean;
};

const LogoutButton: React.FC<Props> = ({ primary }) => (
  <Button
    data-id="logout-button"
    appearance={primary ? "primary" : undefined}
    onClick={() => {
      logout();
    }}
  >
    <FormattedMessage id="navigation.logout" />
  </Button>
);

export default LogoutButton;

import { Icon, IconComponent } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { components, SingleValueProps as ReactSelectSingleValueProps } from "react-select";
import * as css from "../styles.scss";
import { SelectOption } from "../types";

export type SingleValueProps = ReactSelectSingleValueProps<SelectOption>;

const SingleValue: React.FC<SingleValueProps> = (props) => {
  if (props.data.description) {
    return (
      <components.SingleValue {...props}>
        <div className={css.singleValueWithDescriptionWrapper}>
          <span>{props.data.label}</span>
          <span>{props.data.description}</span>
        </div>
      </components.SingleValue>
    );
  }

  if (props.data.multiIconLabel) {
    return (
      <components.SingleValue {...props}>
        <div className={css.multiWrapper}>
          <div className={css.multiIconsWrapper}>
            {props.data.multiIconLabel.map((element: { icon: IconComponent; label: number }, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>
                <Icon InlineIcon={element.icon} extraClasses={css.multiIcon} />
                <span>{element.label}</span>
              </div>
            ))}
          </div>
          <div className={css.multiInfoWrapper}>
            <span>{props.data.label}</span>
          </div>
        </div>
      </components.SingleValue>
    );
  }

  return (
    <components.SingleValue {...props}>
      <div className={css.optionWrapper}>
        {props.data.icon && <span className={css.icon}>{props.data.icon}</span>}
        <span className={css.singleValueLabel}>{props.data.label}</span>
      </div>
    </components.SingleValue>
  );
};

export default SingleValue;

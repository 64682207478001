import { useReactiveVar } from "@apollo/client";
import { NotificationContainer, Notification as HCNotification } from "@flixbus/honeycomb-react";
import * as React from "react";
import usePrevious from "../../hooks/usePrevious/usePrevious";
import * as css from "./Notification.scss";
import compareArraysById from "./helper/compareArraysById";
import { deleteNotification } from "./helper/deleteNotification";
import notificationsVar from "./helper/reactiveVariable";
import { NotificationObject } from "./helper/types";

const Notification: React.FC = () => {
  const notifications = useReactiveVar(notificationsVar);
  const prevNotifications = usePrevious(notifications);

  React.useEffect(() => {
    let newItems: Array<NotificationObject>;

    if (!prevNotifications) {
      newItems = [...notifications];
    } else {
      newItems = compareArraysById(prevNotifications, notifications);
    }

    newItems.forEach((item) => {
      if (!item.isPermanent) {
        setTimeout(() => deleteNotification(item.id), 4000);
      }
    });
  }, [notifications]);

  if (notifications.length > 0) {
    return (
      <NotificationContainer position="bottom-right" extraClasses={css.notificationContainer}>
        {notifications.map((notification) => {
          return (
            <HCNotification
              data-id={notification.id}
              key={notification.id}
              appearance={notification.type}
              toast={!notification.isPermanent}
              closeProps={{
                onClick: () => deleteNotification(notification.id),
                "aria-label": "close notification",
              }}
            >
              {notification.message}
            </HCNotification>
          );
        })}
      </NotificationContainer>
    );
  }
  return <></>;
};
export default Notification;

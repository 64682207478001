import * as React from "react";
import { IntlProvider } from "react-intl";
import { LocaleListItem, getUserPreferredLanguage } from "../../helper";
import { defaultExternalLocale, getLanguageObjectBasedOnLocale } from "../../helper/locales/locales";
import useCurrentLocale from "../../hooks/useCurrentLocale/useCurrentLocale";
import loadTranslations from "./helpers/loadTranslations/loadTranslations";

type Props = {
  usesCookies: boolean;
  isUsedInternal: boolean;
};

const TranslationProvider: React.FC<Props> = ({ usesCookies, isUsedInternal, children }) => {
  const [messages, setMessages] = React.useState({});

  let locale: LocaleListItem;
  if (usesCookies) {
    const lang = getUserPreferredLanguage();
    locale = getLanguageObjectBasedOnLocale(lang, false);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [locale] = useCurrentLocale();
  }

  const setTranslations = async () => {
    setMessages(await loadTranslations(locale.locale, isUsedInternal));
  };

  React.useEffect(() => {
    setTranslations();
  }, [locale]);

  if (Object.keys(messages).length) {
    // We need to expose the messages to the window object to be able to use them in the
    // formatMessage function outside of React components
    window.messages = messages;

    return (
      <IntlProvider
        // https://formatjs.io/docs/react-intl/components/#dynamic-language-selection
        key={locale.bcp47Locale}
        locale={locale.bcp47Locale}
        defaultLocale={defaultExternalLocale.locale}
        messages={messages || {}}
      >
        {children}
      </IntlProvider>
    );
  }

  return <></>;
};

export default TranslationProvider;

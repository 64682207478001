import { Heading, Link, List } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CustomerServiceContactItem, PurposeOfContact } from "../../../../shared/apiTypes/apiTypes";
import * as css from "./CustomerServiceInformationRow.scss";

type Props = {
  data: CustomerServiceContactItem;
  isMain?: boolean;
};

const CustomerServiceInformationRow: React.FC<Props> = ({ data, isMain }) => {
  const { purposeOfContact, hotlineNumber, email, languagesSpoken, timeZoneId, workingHours, holidayWorkingHours } =
    data;

  const getHeadline = () => {
    if (isMain) {
      return <FormattedMessage id="customerServiceInfo.headline.main" />;
    }

    if (purposeOfContact === PurposeOfContact.All) {
      return <FormattedMessage id="customerServiceInfo.headline.additional" />;
    }
    return <FormattedMessage id={`purposeOfContact.headline.${purposeOfContact}`} />;
  };

  return (
    <>
      <Heading size={4} sectionHeader>
        {getHeadline()}
      </Heading>
      <List>
        {(isMain || purposeOfContact === PurposeOfContact.All) && (
          <li data-testid="purpose-of-contact">
            <FormattedMessage
              id="customerServiceInfo.purposeOfContact.text"
              values={{
                reasons:
                  purposeOfContact === PurposeOfContact.All ? (
                    Object.values(PurposeOfContact).map((purpose, i) => {
                      if (purpose !== PurposeOfContact.All) {
                        return (
                          <span key={purpose}>
                            {i !== 0 && <>&#44;&nbsp;</>}
                            <FormattedMessage key={purpose} id={`purposeOfContact.${purpose}`} />
                          </span>
                        );
                      }
                      return null;
                    })
                  ) : (
                    <FormattedMessage id={`purposeOfContact.${purposeOfContact}`} />
                  ),
              }}
            />
          </li>
        )}
        <li data-testid="languages-spoken">
          <FormattedMessage
            id="customerServiceInfo.languages.text"
            values={{
              languages: languagesSpoken.map((lang, i) => (
                <span key={lang}>
                  {i !== 0 && <>&#44;&nbsp;</>}
                  <FormattedMessage key={lang} id={`language.${lang}`} />
                </span>
              )),
            }}
          />
        </li>
        <li data-testid="hotline-number">
          <FormattedMessage
            id="customerServiceInfo.hotlineNumber.text"
            values={{
              hotlineNumber: <Link href={`tel:${hotlineNumber}`}>{hotlineNumber}</Link>,
            }}
          />
        </li>
        <li data-testid="email">
          <FormattedMessage
            id="customerServiceInfo.email.text"
            values={{
              email: <Link href={`mailto:${email}`}>{email}</Link>,
            }}
          />
        </li>
        {workingHours.length > 0 && (
          <li data-testid="working-hours">
            <FormattedMessage id="customerServiceInfo.workingHours.label" />
            <List>
              {workingHours.map(({ workingDays, from, to }, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                  {workingDays.map((dayOfWeek, index) => (
                    <span key={dayOfWeek}>
                      <FormattedMessage id={`general.weekday.short.${dayOfWeek}`} />
                      {index !== workingDays.length - 1 ? ", " : ""}
                    </span>
                  ))}
                  <span className={css.divider}>&nbsp;|&nbsp;</span>
                  <FormattedMessage id="customerServiceInfo.workingHours.time" values={{ from, to }} />
                  <span className={css.divider}>&nbsp;|&nbsp;</span>
                  <FormattedMessage
                    id="customerServiceInfo.workingHours.timeZone.text"
                    values={{ timezone: timeZoneId }}
                  />
                </li>
              ))}
              {holidayWorkingHours.map(({ from, to }, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                  <FormattedMessage id="general.publicHoliday" />
                  <span className={css.divider}>&nbsp;|&nbsp;</span>
                  <FormattedMessage id="customerServiceInfo.workingHours.time" values={{ from, to }} />
                  <span className={css.divider}>&nbsp;|&nbsp;</span>
                  <FormattedMessage
                    id="customerServiceInfo.workingHours.timeZone.text"
                    values={{ timezone: timeZoneId }}
                  />
                </li>
              ))}
            </List>
          </li>
        )}
      </List>
    </>
  );
};

export default CustomerServiceInformationRow;

const supportedGetParams = [
  // MPC
  "partner",
  "line",
  "schedule",
  "token",
  "id_token",
  "access_token",
  "forbidden_relations",
  "country",
  "status",
  "station_id",
  "station_name",
  // MPO
  "date",
  "returnTo",
  "lineId",
  "departureStationId",
  "departureTime",
  "startDate",
  "endDate",
  "departureDays",
  "tracking_channel",
  "report",
];
export type SupportedGETParams = {
  [K in (typeof supportedGetParams)[number]]?: string;
};

export const readURIParams = (query: string, isGetParams = true): Partial<SupportedGETParams> => {
  const delimiter = isGetParams ? "?" : "#";
  const params: Partial<SupportedGETParams> = {};
  const res = new URLSearchParams(query.split(delimiter)[1]);

  supportedGetParams.forEach((key) => {
    const value = res.get(key) || "";
    if (value.length > 0) {
      params[key as keyof SupportedGETParams] = value;
    }
  });

  return params;
};

import { getRoles, isTestEnv, isUnitTest } from "../utils/utils";
import Feature from "./Feature";
import Role from "./Role";
import * as permissionsYml from "./marketplace-permissions/permissions.yml";

export type PermissionsObject = {
  [key in Feature]: Array<Role>;
};

export const hasUserDataWallAccess = () => {
  if (isTestEnv()) {
    return true;
  }

  const roles = getRoles();

  if (roles && roles.includes(Role.ROLE_STATION_MANAGER)) {
    return true;
  }

  if (roles && roles.length) {
    return roles.includes(Role.DATA_WALL);
  }
  return false;
};

export const hasUserPermission = (feature: Feature) => {
  const permissionsObject: PermissionsObject = permissionsYml;
  const roles = getRoles();

  if (isUnitTest()) {
    return false;
  }

  if (isTestEnv()) {
    return true;
  }

  if (roles && roles.length) {
    if (permissionsObject[feature]) {
      return permissionsObject[feature].some((role) => roles.includes(role));
    }
    return false;
  }

  return false;
};

export const hasUserAtLeastOnePermission = (features: Array<Feature>) => {
  return features.some((feature) => hasUserPermission(feature));
};

import { useSearchParams } from "react-router-dom";
import { defaultExternalLocale, externalLocales } from "../../helper/locales/locales";
import { LocaleListItem } from "../../helper/locales/types";

export const LOCALE_KEY = "lang";

export const getLocaleFromSearchParam = (param: string | null): Required<LocaleListItem> | undefined => {
  return externalLocales.find((lang) => lang.locale === param);
};

export const getPreferredLocaleFromBrowser = (): Required<LocaleListItem> | undefined => {
  const browserLocale = externalLocales.find((lang) => lang.bcp47Locale === navigator.language);

  if (browserLocale) {
    return browserLocale;
  }

  return externalLocales.find((lang) => lang.bcp47Locale === navigator.language.split("-")[0]);
};

const useCurrentLocale = (): [Required<LocaleListItem>, (lang: LocaleListItem) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setLocale = (lang: LocaleListItem) => {
    setSearchParams(`${LOCALE_KEY}=${lang.locale}`);
  };

  const localeFromSearchParam = getLocaleFromSearchParam(searchParams.get(LOCALE_KEY));
  const localeFromBrowser = getPreferredLocaleFromBrowser();

  if (localeFromSearchParam) {
    return [localeFromSearchParam, setLocale];
  }

  if (localeFromBrowser) {
    setLocale(localeFromBrowser);
    return [localeFromBrowser, setLocale];
  }

  setLocale(defaultExternalLocale);
  return [defaultExternalLocale, setLocale];
};

export default useCurrentLocale;

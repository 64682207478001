export type BrandsList = Array<{ name: string; uuid: string }>;

export enum WeightUnit {
  KILOGRAMS = "KILOGRAMS",
  POUNDS = "POUNDS",
}

export enum LengthUnit {
  CENTIMETERS = "CENTIMETERS",
  INCHES = "INCHES",
}

export enum TimeUnit {
  HOURS = "HOURS",
  MINUTES = "MINUTES",
  DAYS = "DAYS",
}

export enum PurposeOfContact {
  Refund = "REFUND",
  Feedback = "FEEDBACK",
  Luggage = "LUGGAGE",
  PassengersWithDisabilities = "PASSENGERS_WITH_DISABILITIES",
  ChildrenReservation = "CHILDREN_RESERVATION",
  All = "ALL",
  DelayInformation = "DELAY_INFORMATION",
}

export enum FeeType {
  PRICE_PER_PIECE = "PRICE_PER_PIECE",
  PRICE_PER_WEIGHT = "PRICE_PER_WEIGHT",
}

export enum DayOfWeek {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export type CustomerServiceContactItem = {
  purposeOfContact: PurposeOfContact;
  hotlineNumber: string;
  email: string;
  languagesSpoken: Array<string>;
  timeZoneId: string;
  workingHours: Array<{
    workingDays: Array<DayOfWeek>;
    from: string;
    to: string;
  }>;
  holidayWorkingHours: Array<{
    from: string;
    to: string;
  }>;
};

export type PartnerPoliciesData = {
  termsAndConditionsUrl: string;
  checkInTime: {
    start: {
      value: number;
      unit: TimeUnit;
    } | null;
    end: {
      value: number;
      unit: TimeUnit;
    } | null;
  };
  customerServiceContactV2: Array<CustomerServiceContactItem>;
};

export type IncludedLuggagePolicy = {
  luggageSpecification: {
    maxWeight: {
      value: number;
    } | null;
    maxDimension: {
      value: string;
    } | null;
    maxPieces: number | null;
  } | null;
  inTicketIncluded: boolean;
};

export type BookableLuggagePolicy = {
  luggageSpecification: {
    maxWeight: {
      value: number;
    } | null;
    maxDimension: {
      value: string;
    } | null;
    feeType: FeeType | null;
    feePrice: {
      value: number;
    } | null;
    pointOfSale: "CUSTOMER_SERVICE" | "ON_BOARD" | null;
    bookableUntil: {
      value: number;
      unit: TimeUnit;
    } | null;
  } | null;
  bookable: boolean;
};

export type UnaccompaniedMinorAllowanceRule = {
  parentalConsentRequired: boolean;
} | null;

export type UnaccompaniedMinorPolicy = {
  unaccompaniedMinorSpecification: {
    minAgeYears: number | null;
    internationalTravel: UnaccompaniedMinorAllowanceRule;
    nightTravel: UnaccompaniedMinorAllowanceRule;
    interconnectionTravel: UnaccompaniedMinorAllowanceRule;
  } | null;
  allowed: boolean;
};

export type PassengerWithDisabilityPolicy = {
  passengerWithDisabilitySpecification: {
    proofOfDisabilityRequired: boolean | null;
    registrationUntil: {
      value: number;
      unit: TimeUnit;
    } | null;
    supportingServices: Array<"FOLDING_WHEELCHAIR_ALLOWED" | "SERVICE_DOG_ALLOWED" | "ELECTRIC_WHEELCHAIR_ALLOWED">;
  } | null;
  supported: boolean;
};

export type PolicyUnits = {
  weightUnit: WeightUnit;
  lengthUnit: LengthUnit;
  currency:
    | "ALL"
    | "BAM"
    | "BGN"
    | "BRL"
    | "CHF"
    | "CZK"
    | "DKK"
    | "EUR"
    | "GBP"
    | "HRK"
    | "HUF"
    | "INR"
    | "MKD"
    | "NOK"
    | "PLN"
    | "RON"
    | "RSD"
    | "RUB"
    | "SEK"
    | "TRY"
    | "UAH"
    | "USD"
    | "MXN"
    | "CAD";
};

export enum BrandPolicyType {
  BOTH = "BOTH",
  DOMESTIC = "DOMESTIC",
  INTERNATIONAL = "INTERNATIONAL",
}

export type BrandPoliciesData = {
  type: BrandPolicyType;
  luggagePolicy: {
    handLuggagePolicy: IncludedLuggagePolicy | null;
    checkInLuggagePolicy: IncludedLuggagePolicy | null;
    additionalLuggagePolicy: BookableLuggagePolicy | null;
    specialLuggagePolicy: BookableLuggagePolicy | null;
    pushchairPolicy: BookableLuggagePolicy | null;
    bikePolicy: BookableLuggagePolicy | null;
    surfboardPolicy: BookableLuggagePolicy | null;
    skiEquipmentPolicy: BookableLuggagePolicy | null;
    petPolicy: BookableLuggagePolicy | null;
  } | null;
  passengerPolicy: {
    unaccompaniedMinorPolicy: UnaccompaniedMinorPolicy | null;
    passengerWithDisabilityPolicy: PassengerWithDisabilityPolicy | null;
  } | null;
  additionalInformation: string;
};

export type BrandData = {
  brandName: string;
  legalCompanyName: string;
  partnerPolicies: PartnerPoliciesData;
  brandPolicies: {
    policyUnits: PolicyUnits;
    policies: Array<BrandPoliciesData>;
  };
};

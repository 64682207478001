import * as React from "react";
import { useIntl } from "react-intl";
import { BrandPoliciesData, PolicyUnits } from "../../shared/apiTypes/apiTypes";
import { brandPolicyTitles } from "../../shared/helpers/accordionTitles/accordionTitles";
import PolicyAccordion from "../policyAccordion/PolicyAccordion";
import BookableLuggage from "./ui/bookableLuggage/BookableLuggage";
import IncludedLuggage from "./ui/includedLuggage/IncludedLuggage";
import PassengerWithDisability from "./ui/passengerWithDisability/PassengerWithDisability";
import YoungTravellers from "./ui/youngTravellers/YoungTravellers";

type BrandPoliciesProps = {
  policies?: BrandPoliciesData;
  policyUnits: PolicyUnits;
  hasContactInfo: boolean;
};

const BrandPolicies: React.FC<BrandPoliciesProps> = ({ policies, policyUnits, hasContactInfo }) => {
  const intl = useIntl();

  const { luggagePolicy, passengerPolicy } = policies || {
    luggagePolicy: null,
    passengerPolicy: null,
  };

  return (
    <>
      <PolicyAccordion
        title={intl.formatMessage({ id: brandPolicyTitles[0].title })}
        InlineIcon={brandPolicyTitles[0].icon}
        loading={false}
        hasContactInfo={hasContactInfo}
      >
        {luggagePolicy?.handLuggagePolicy && (
          <IncludedLuggage policyName="handLuggagePolicy" data={luggagePolicy.handLuggagePolicy} units={policyUnits} />
        )}

        {luggagePolicy?.checkInLuggagePolicy && (
          <IncludedLuggage
            policyName="checkInLuggagePolicy"
            data={luggagePolicy.checkInLuggagePolicy}
            units={policyUnits}
          />
        )}

        {luggagePolicy?.additionalLuggagePolicy && (
          <BookableLuggage
            policyName="additionalLuggagePolicy"
            data={luggagePolicy.additionalLuggagePolicy}
            units={policyUnits}
          />
        )}
      </PolicyAccordion>

      <PolicyAccordion
        title={intl.formatMessage({ id: brandPolicyTitles[1].title })}
        InlineIcon={brandPolicyTitles[1].icon}
        loading={false}
        hasContactInfo={hasContactInfo}
      >
        {luggagePolicy?.specialLuggagePolicy && (
          <BookableLuggage
            policyName="specialLuggagePolicy"
            data={luggagePolicy.specialLuggagePolicy}
            units={policyUnits}
          />
        )}

        {luggagePolicy?.pushchairPolicy && (
          <BookableLuggage policyName="pushchairPolicy" data={luggagePolicy.pushchairPolicy} units={policyUnits} />
        )}
      </PolicyAccordion>

      <PolicyAccordion
        title={intl.formatMessage({ id: brandPolicyTitles[2].title })}
        InlineIcon={brandPolicyTitles[2].icon}
        loading={false}
        hasContactInfo={hasContactInfo}
      >
        {passengerPolicy?.unaccompaniedMinorPolicy && (
          <YoungTravellers data={passengerPolicy.unaccompaniedMinorPolicy} />
        )}
      </PolicyAccordion>

      <PolicyAccordion
        title={intl.formatMessage({ id: brandPolicyTitles[3].title })}
        InlineIcon={brandPolicyTitles[3].icon}
        loading={false}
        hasContactInfo={hasContactInfo}
      >
        {passengerPolicy?.passengerWithDisabilityPolicy && (
          <PassengerWithDisability data={passengerPolicy.passengerWithDisabilityPolicy} />
        )}
      </PolicyAccordion>

      <PolicyAccordion
        title={intl.formatMessage({ id: brandPolicyTitles[4].title })}
        InlineIcon={brandPolicyTitles[4].icon}
        loading={false}
        hasContactInfo={hasContactInfo}
      >
        {luggagePolicy?.bikePolicy && (
          <BookableLuggage policyName="bikePolicy" data={luggagePolicy.bikePolicy} units={policyUnits} />
        )}

        {luggagePolicy?.surfboardPolicy && (
          <BookableLuggage policyName="surfboardPolicy" data={luggagePolicy.surfboardPolicy} units={policyUnits} />
        )}

        {luggagePolicy?.skiEquipmentPolicy && (
          <BookableLuggage
            policyName="skiEquipmentPolicy"
            data={luggagePolicy.skiEquipmentPolicy}
            units={policyUnits}
          />
        )}
      </PolicyAccordion>

      <PolicyAccordion
        title={intl.formatMessage({ id: brandPolicyTitles[5].title })}
        InlineIcon={brandPolicyTitles[5].icon}
        loading={false}
        hasContactInfo={hasContactInfo}
      >
        {luggagePolicy?.petPolicy && (
          <BookableLuggage policyName="petPolicy" data={luggagePolicy.petPolicy} units={policyUnits} />
        )}
      </PolicyAccordion>
    </>
  );
};

export default BrandPolicies;

import { BrandsList } from "../../../../shared/apiTypes/apiTypes";
import normalizeString from "../normalizeString/normalizeString";

const filterBrands = (brands: BrandsList, filterValue?: string): BrandsList => {
  if (filterValue) {
    const cleanFilterValue = filterValue?.trim();
    const normalizedFilterValue = normalizeString(cleanFilterValue);

    return brands.filter(({ name }) => normalizeString(name).includes(normalizedFilterValue));
  }
  return brands.sort((a, b) => a.name.localeCompare(b.name));
};

export default filterBrands;

import { ListWrapper, ListWrapperItem } from "@flixbus/honeycomb-react";
import { ErrorPage, ErrorTypes } from "@flixbus-phx/marketplace-common";
import useAxios from "axios-hooks";
import * as React from "react";
import { BrandsList } from "../../shared/apiTypes/apiTypes";
import LinkWithQuery from "../../shared/linkWithQuery/LinkWithQuery";
import filterBrands from "./helper/filterBrands/filterBrands";
import highlightText from "./helper/highlightText/highlightText";
import BrandListNoResults from "./ui/brandListNoResults/BrandListNoResults";
import BrandListSkeleton from "./ui/brandListSkeleton/BrandListSkeleton";

type Props = {
  filterValue?: string;
};

const BrandList: React.FC<Props> = ({ filterValue }) => {
  const [{ data, loading, error }] = useAxios<BrandsList>(`${process.env.API!}/brands.json`);

  if (error) return <ErrorPage type={ErrorTypes.e500} />;

  if (loading) {
    return <BrandListSkeleton data-testid="foo" />;
  }

  if (data) {
    const filteredBrands = filterBrands(data, filterValue).map(({ name, uuid }, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <ListWrapperItem key={`${i}-${uuid}`} to={`/${uuid}`} RouterLink={LinkWithQuery}>
        {highlightText(name, filterValue)}
      </ListWrapperItem>
    ));

    if (filteredBrands.length) {
      return <ListWrapper aria-busy="false">{filteredBrands}</ListWrapper>;
    }
  }

  return <BrandListNoResults filterValue={filterValue} />;
};

export default BrandList;

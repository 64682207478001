import { Icon, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Spinner } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./NoOptionsMessage.scss";

type Props = {
  show: boolean;
  loading?: boolean;
  noOptionsAction?: {
    action: () => void;
    message?: string;
  };
  noOptionsInfo?: JSX.Element;
};

const NoOptionsMessage: React.FC<Props> = ({ noOptionsAction, noOptionsInfo, show, loading }) => {
  if (show) {
    if (loading) {
      return (
        <div className={css.loading}>
          <Spinner size="sm" />
        </div>
      );
    }

    if (noOptionsAction) {
      return (
        <div data-id="no-options-action-info" className={css.noOption} onClick={noOptionsAction.action}>
          {noOptionsAction.message}
          <Icon extraClasses={css.icon} InlineIcon={IconPlus} />
        </div>
      );
    }

    if (noOptionsInfo) {
      return <div data-id="no-options-info">{noOptionsInfo}</div>;
    }
    return null;
  }
  return null;
};

export default NoOptionsMessage;

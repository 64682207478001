import { createIntl, createIntlCache, PrimitiveType } from "react-intl";
import { getLanguageObjectBasedOnLocale } from "../../../../helper/locales/locales";
import { getUserPreferredLanguage } from "../../../../helper/userPreferredLanguage/userPreferredLanguage";

/*
 * Function to Format Messages outside of React Components
 * Works only if selected languages is stored as cookie and only returnst translations
 * for internal langs
 */
export default (id: string, values?: Record<string, PrimitiveType>): string => {
  const { messages } = window;
  const locale = getLanguageObjectBasedOnLocale(getUserPreferredLanguage(), false);

  if (messages && Object.keys(messages).length) {
    const cache = createIntlCache();

    const intl = createIntl(
      {
        locale: locale.bcp47Locale,
        messages: messages || {},
      },
      cache
    );

    return intl.formatMessage({ id }, values);
  }

  if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
    console.error(
      `Attention: You are calling formatMessage outside of React Components from within a constant. You need to change your constant into a function and call it from within a React Component. Call of common/formatMessages for key: ${id}`
    );
  }

  return JSON.stringify({ id, ...values });
};

import { Divider } from "@flixbus/honeycomb-react";
import * as React from "react";
import { getLanguageObjectBasedOnLocale } from "../../helper/locales/locales";
import * as css from "./Footer.scss";
import { FOOTER_MENU_ITEM, queryFooterMenu } from "./helper/api";

type Props = {
  locale: string;
  isInternal: boolean;
};

const CURRENT_YEAR = new Date().getFullYear();

const Footer: React.FC<Props> = ({ locale, isInternal }) => {
  const [menuItems, setMenuItems] = React.useState<Array<FOOTER_MENU_ITEM>>([]);
  const [domain, setDomain] = React.useState<string>("");

  const languageObject = getLanguageObjectBasedOnLocale(locale, isInternal);

  React.useEffect(() => {
    queryFooterMenu(languageObject.drupal)
      .then((res) => {
        setDomain(res.data.domains[0].name);
        setMenuItems(res.data.menu.items);
      })
      .catch((e) => {
        console.error(e.message);
      });
  }, []);

  return (
    <div className={css.container}>
      <Divider />
      <div className={css.flexContainer}>
        <div className={css.linkFlexContainer}>
          {menuItems.map((item) => {
            if (item.url.path === "/manage-consent-settings") {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  key={item.url.path}
                  href="#"
                  onClick={() => {
                    if (window.UC_UI) {
                      window.UC_UI.updateLanguage(languageObject.iso6391);
                      window.UC_UI.showSecondLayer();
                    }
                  }}
                  className={css.text}
                >
                  {item.title}
                </a>
              );
            }
            return (
              <a
                key={item.url.path}
                rel="noreferrer"
                target="_blank"
                href={item.url.path.startsWith("/") ? `https://${domain}${item.url.path}` : item.url.path}
                className={css.text}
              >
                {item.title}
              </a>
            );
          })}
        </div>
        <div className={css.text}>&copy; {CURRENT_YEAR} Flix SE</div>
      </div>
    </div>
  );
};

export default Footer;
